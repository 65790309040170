import { CurrencyModel } from 'src/app/shared/models/currency';
import { CampaignRewardModel } from '../../campaign-create-v3/models/campaign-reward.model';
import { AssetDetailModel } from '../../asset-management/models/asset-mgmt.model';
import { PlatformModel } from '../../campaign-create-v3/models/campaign.model';

export interface CampaignsInterface {
	CampaignId?: string;
	Id: string;
	Name: string;
	BudgetAmount: number;
	Impression: number;
	Engagements: number;
	CampaignBudgetType: string;
	CampaignObjective: string;
	Currency: string;
	Status: string;
	CreatedByFirstName: string;
	CreatedById: number;
	CreatedByLastName: string;
	UpdatedAt: string;
	UpdatedByFirstName: string;
	UpdatedById: number;
	UpdatedByLastName: string;
	InfluencerId?: string;
}

export enum FilterTypeEnum {
	LOCATIONS = <any>'Location',
	STATE = <any>'State',
	CITY = <any>'City',
	LANGUAGES = <any>'Language',
	GENDER = <any>'Gender',
	TIER = <any>'Tier',
	HASHTAGS = <any>'Hashtag',
	CATEGORIES = <any>'Interest',
	HASEMAIL = <any>'Has Email',
	AGE = <any>'Age',
	IG = <any>'IG',
	TK = <any>'TK',
	YT = <any>'YT',
	AUGENDER = <any>'Audience Gender',
	AULOCATION = <any>'Audience Location',
	AUAGE = <any>'Audience Age',
	AU_AGE = <any>'Audience Age',
	AULANGUAGE = <any>'Audience Language',
	AUINTERESTS = <any>'Audience Interests',
	FLW = <any>'Followers',
	// MINFOLLOWERS = <any>'Followers',
	// MAXFOLLOWERS = <any>'Followers',
	ER = <any>'Engagement Rate',
	EPP = <any>'Average Interactions',
	// MINENGAGEMENTS = <any>'Average Interactions',
	// MAXENGAGEMENTS = <any>'Average Interactions',
	BK = <any>'Bio Keyword',
	IV = <any>'Is Verified',
	AT = <any>'Account Type',
	VW = <any>'Overall Views',
	AVPP = <any>'Average Views Per Post',
	PF = <any>'Post Frequency'
}

export interface campaignObjective {
	id: number;
	name: string;
}
export interface commonDataModel {
	id?: number;
	name: string;
	region?: string;
	countryName?: string;
	type?: string;
}
export interface fieldType {
	id: string;
	name: string;
	value?: string;
	type?: string;
}
export enum FilterType {
	INFLUENCER = 'influencer',
	AUDIENCE = 'audience'
}
export interface campaignCurrency {
	id: number;
	name: string;
	iso: string;
	symbol: string;
}

export interface budgetType {
	id: number;
	name: string;
}
export interface campaignForm {
	name: string;
	budget_amount: number;
	currency: number;
	currency_id: number;
	budget_type: number;
	description: string;
	campaign_link: string;
	tracking_link: string;
}

export interface categories {
	categories: string[];
}

export interface CampaignOneJobSummaryDataModel {
	campaign?: CampaignSummaryDataModel;
	job?: JobSummaryDataModel;
}

export interface CampaignJobSummaryDataModel {
	campaign?: CampaignSummaryDataModel;
	job?: JobSummaryDataModel[];
}

export interface CampaignSummaryDataModel {
	budget?: number;
	currency?: CurrencyModel;
	name?: string;
	payment_mode?: string;
	website?: string;
	locations?: string[];
	payout_details?: CampaignPayoutDetailsModel;
	campaign_reward?: CampaignRewardModel;
	campaign_offer?: CampaignRewardModel;
	referral_links?: CampaignReferralLinksModel;
	ecommerce_data?: CampaignEcommerceDataModel[];
	assets?: AssetDetailModel[];
	auto_approval?: boolean;
}

export interface JobSummaryDataModel {
	budget?: number;
	categories?: string[];
	desc_file?: string;
	due_date?: string;
	job_desc?: string;
	job_image?: string;
	name?: string;
	platforms?: PlatformModel[];
	referral_links?: any;
}

export interface CampaignReferralLinksModel {
	default: DefaultReferralLinkModel;
	additional?: AdditionalReferralLinksModel[];
}

export interface DefaultReferralLinkModel {
	url: string;
	param: string;
}

export interface AdditionalReferralLinksModel {
	name: string;
	url: string;
}

export interface CampaignPayoutDetailsModel {
	payout_frequency?: CampaignPayoutFrequencyModel;
	min_payout?: number;
	payout_provider?: CampaignPayoutProviderModel;
}

export interface CampaignPayoutProviderModel {
	name: string;
	id: number;
}

export interface CampaignPayoutFrequencyModel {
	name: string;
	id: number;
}

export interface CampaignEcommerceDataModel {
	catalog_id?: number;
	catalog_name?: string;
	products?: CampaignProductModel[];
}

export interface CampaignProductModel {
	id?: number;
	title?: string;
	description?: string;
	category?: string;
	currency?: string;
	price?: number;
	sku?: string;
	image_url?: string;
	catalog_id?: number;
	quantity?: number;
}
