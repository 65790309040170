import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomTextInputTypeEnum } from '../models/custom-text-input.model';

@Component({
	selector: 'app-custom-text-input',
	templateUrl: './custom-text-input.component.html',
	styleUrls: ['./custom-text-input.component.scss']
})
export class CustomTextInputComponent implements OnInit {
	@Input() inputLabel: string = '';
	@Input() inputType: CustomTextInputTypeEnum = CustomTextInputTypeEnum.TEXT;
	@Input() markRequired: boolean = false;
	@Input() showCloseIcon: boolean = false;
	@Input() showCloseIconOnlyOnHover: boolean = false;
	@Input() inputControl: UntypedFormControl = new UntypedFormControl(null, [Validators.min(444)]);
	@Output() onEnterKeyPressed: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCloseClick: EventEmitter<void> = new EventEmitter<void>();

	public isPasswordVisible: boolean = false;
	public CustomTextInputTypeEnum = CustomTextInputTypeEnum;

	constructor() {}

	ngOnInit(): void {}

	public togglePasswordVisibility(): void {
		this.isPasswordVisible = !this.isPasswordVisible;
	}

	public onEnterKeyPressEvent(): void {
		this.onEnterKeyPressed.emit();
	}

	public onCloseBtnClick(): void {
		this.onCloseClick.emit();
	}
}
