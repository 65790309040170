<div class="d-flex flex-column" style="gap: 16px;">
	<div class="d-flex k-align-items-center w-100" style="gap: 20px;">
		<div class="p-bar-title">{{ title }}</div>

		<div class="progress-percent-box">{{ (selectedStep / stepCount) * 100 | number: '1.0-0' }} %</div>
	</div>

	<div class="progress-bar-overlay-wrapper" [ngStyle]="{ gap: stepSpacing }">
		<ng-container *ngFor="let step of stepsArray">
			<div class="progress-bar-step" [ngClass]="{ filled: step < selectedStep }"></div>
		</ng-container>
	</div>
</div>
