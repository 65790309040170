import { UserRoleEnum } from 'src/app/shared-components/models/user-role.model';

export enum AuthTypeEnum {
	EMAIL_PASSWORD = 'email_password',
	GOOGLE = 'google'
}

export enum UserAccountStateEnum {
	COMPLETE = 1,
	ONGOING = 2,
	PENDING = 3,
	FAILED = 4
}

export interface UserJWTDetailsInterface {
	sub?: string;
	exp?: number;
	iss?: string;
	aud?: string;
	permissions_filed?: string;
	user_account_state?: string;
	user_filed_id?: string;
	user_firstname?: string;
	user_lastname?: string;
	sub_user?: boolean;
	company_name?: string;
	first_time_campaign?: boolean;
	first_time_crm?: boolean;
	first_time_discovery_list?: boolean;
	first_time_user?: boolean;
	parent_user_id?: number;
	phone_number?: string;
	username?: string;
	user_type?: string;
	role?: UserRoleEnum;
}
