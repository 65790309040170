import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { PaymentLinkStatusEnum } from 'src/app/shared-components/models/payment-status.enum';
import { SharedService } from '../services/shared.service';
import { UserAccountStateEnum, UserJWTDetailsInterface } from 'src/app/authentication-v2/models/auth.model';
import { OnboardingService } from 'src/app/social-media-influencer/modules/onboarding/services/onboarding.service';
import { UserOnboardingStepEnum } from 'src/app/social-media-influencer/modules/onboarding/models/onboarding.model';

@Injectable({
	providedIn: 'root'
})
export class SmiV2Guard implements CanActivate {
	constructor(
		private authV2Service: AuthenticationV2Service,
		private router: Router,
		private sharedService: SharedService,
		private onboardingService: OnboardingService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isUserLoggedIn = this.authV2Service.isUserSignedIn();
		if (!isUserLoggedIn) {
			this.router.navigate(['/authentication']);
			return of(false);
		}
		const userDetails: UserJWTDetailsInterface = this.authV2Service.getCurrentDecodedToken();

		const userAccountState = parseInt(userDetails.user_account_state);
		if (userAccountState == UserAccountStateEnum.PENDING || userAccountState == UserAccountStateEnum.ONGOING) {
			this.getPaymentLink();
			return of(false);
		}

		this.getUserOnboardingStatus()
			.then(res => {
				if (res === true) {
					this.router.navigate(['/onboarding']);
					return of(false);
				}
				return of(true);
			})
			.catch(err => {
				return of(false);
			});
	}

	public getPaymentLink(): void {
		const token = localStorage.getItem(StorageKey.token);
		this.sharedService
			.getUserPaymentStatus()
			.pipe(take(1))
			.subscribe(
				res => {
					if (res.status == PaymentLinkStatusEnum.SUCCESS) {
						const paymentLink = res.data;
						window.open(paymentLink, '_self');
					} else {
						this.router.navigate(['/authentication/payment'], { queryParams: { token } });
					}
				},
				err => {}
			);
	}

	public getUserOnboardingStatus(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.onboardingService
				.getUserOnboardingStatus()
				.pipe(take(1))
				.subscribe(
					res => {
						if (res?.current_step < UserOnboardingStepEnum.COMPLETED) {
							resolve(true);
						} else {
							resolve(false);
						}
					},
					err => {
						reject(err);
					}
				);
		});
	}
}
