import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../shared/utils';

declare var Calendly: any;

@Component({
	selector: 'app-book-demo',
	templateUrl: './book-demo.component.html',
	styleUrls: ['./book-demo.component.scss']
})
export class BookDemoComponent implements OnInit {
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	public isFirstTimePlay: boolean = true;

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		Calendly.initInlineWidget({
			url: UtilsService.BOOK_DEMO_URL,
			parentElement: document.getElementById('calendly-embed'),
			prefill: {},
			utm: {}
		});
	}

	public async togglePlayPause() {
		if (this.isFirstTimePlay) {
			this.isFirstTimePlay = false;
		}
		const video: HTMLVideoElement = this.videoPlayer.nativeElement;

		if (video.paused) {
			await video.play();
		} else {
			await video.pause();
		}
	}

	public openInNewTab(url: string): void {
		window.open(url, '_blank');
	}
}
