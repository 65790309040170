<div class="page-root">
	<div class="page-wrapper">
		<div class="left-side">
			<div class="left-content">
				<div id="calendly-embed" class="calendly-inline-widget" data-auto-load="false"></div>
			</div>
		</div>

		<div class="right-side">
			<img class="lolly-transparent" src="assets/icons/lolly-white-transparent.svg" />

			<div class="right-content">
				<div class="text">
					Boost ROI and brand awareness, smarter influencer marketing with Lolly!
				</div>

				<div class="video-wrapper" (click)="togglePlayPause()">
					<video
						#videoPlayer
						poster="https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png"
						src="https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4"
					></video>
					<mat-icon
						class="open-in-new-tab"
						(click)="
							$event.stopPropagation(); openInNewTab('https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4')
						"
					>
						open_in_new
					</mat-icon>
					<img *ngIf="videoPlayer?.paused" src="assets/icons/video-play.svg" class="center-icon {{ isFirstTimePlay ? 'visibleimp' : '' }}" />
					<img *ngIf="!videoPlayer?.paused" src="assets/icons/video-pause.svg" class="center-icon" />
				</div>
			</div>
		</div>
	</div>
</div>
