<div class="dialog-root">
	<mat-icon mat-dialog-close class="close-btn">
		close
	</mat-icon>

	<div class="dialog-header">
		{{data?.title ?? 'Create New Item'}}
	</div>

	<div class="dialog-body">
		<div class="left">
			{{data?.subtitle ?? 'New Item'}}
		</div>
		<div class="right">
			<div *ngIf="suffixText" class="suffix-input-wrapper">
				<input [formControl]="nameControl" type="text" class="text-input" [placeholder]="data?.placeholderName ?? 'Enter name'" />
				<div class="suffix-text">{{ suffixText }}</div>
			</div>
			<input
				*ngIf="!suffixText"
				type="text"
				class="custom-input-name"
				[formControl]="nameControl"
				[placeholder]="data?.placeholderName ?? 'Enter name'"
			/>
		</div>
	</div>

	<div class="dialog-body">
		<div class="left">
			{{data?.description ?? 'New Description'}}
		</div>
		<div class="">
			<div *ngIf="suffixDescription" class="">
				<textarea
					[formControl]="descriptionControl"
					type="text"
					class=""
					[placeholder]="data?.placeholderDescription ?? 'Enter description'"
				></textarea>
				<div class="">{{ suffixDescription }}</div>
			</div>
			<textarea
				*ngIf="!suffixDescription"
				type="text"
				class="custom-textarea"
				[formControl]="descriptionControl"
				[placeholder]="data?.placeholderDescription ?? 'Enter description'"
			></textarea>
		</div>
	</div>

	<div class="dialog-footer">
		<button class="quaternary-btn" mat-dialog-close>Cancel</button>
		<button
			class="primary-btn"
			[class.disabled]="
				!this.nameControl?.value ||
				this.nameControl?.value?.trim() == '' ||
				(this.nameControl?.invalid && !this.descriptionControl?.value) ||
				this.descriptionControl?.value?.trim() == '' ||
				this.descriptionControl?.invalid
			"
			(click)="primaryAction()"
		>
			{{data?.primaryButton ?? 'Create'}}
		</button>
	</div>
</div>
