import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
	@Input() public selectedStep: number = 0; // Starts from 0
	@Input() public stepSpacing: string = '10px';
	@Input() public title: string = '';

	public stepsArray: number[] = [];

	private _stepCount: number;

	@Input()
	set stepCount(value: number) {
		this._stepCount = value;
		this.stepsArray = Array.from({ length: this._stepCount }, (_, i) => i);
	}

	get stepCount(): number {
		return this._stepCount;
	}

	constructor() {}

	ngOnInit(): void {
		// Assign a default value to stepCount
		if (!this.stepCount) {
			this.stepCount = 4;
		}
	}
}
