import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-select-dropdown-v2',
	templateUrl: './select-dropdown-v2.component.html',
	styleUrls: ['./select-dropdown-v2.component.scss']
})
export class SelectDropdownV2Component implements OnInit {
	@Input() inputLabel: string = '';
	@Input() dropdownControl: UntypedFormControl = new UntypedFormControl();
	@Input() options: any[];
	@Input() disabled: boolean = false;
	@Input() showNullOption: boolean = true;
	@Input() titleKey: string = 'name';
	@Input() nullOptionName: string = 'Select';

	@Output() selectionEvent = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	public optionSelected(event: any) {
		this.selectionEvent.emit(event);
	}
}
