import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { Store } from '@ngrx/store';
import { UserState } from '../state/user/user.reducer';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';

@Injectable({
	providedIn: 'root'
})
export class UserBlockGuard implements CanActivate {
	constructor(public authV2Service: AuthenticationV2Service, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authV2Service.isUserSignedIn()) {
			return of(true);
		}
		this.authV2Service.signoutUser();
		return of(false);
	}
}
