import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UtilsService } from 'src/app/shared/utils';

@Component({
	selector: 'app-generic-two-input-dialog',
	templateUrl: './generic-two-input-dialog.component.html',
	styleUrls: ['./generic-two-input-dialog.component.scss']
})
export class GenericTwoInputDialogComponent implements OnInit {
	public formGroup: UntypedFormGroup;
	public nameControl: UntypedFormControl;
	public suffixText?: string;

	public descriptionControl: UntypedFormControl;
	public suffixDescription?: string;

	constructor(
		private toastNotificationService: ToastNotificationService,
		public dialogRef: MatDialogRef<GenericTwoInputDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.suffixText = data?.suffixText;
		this.suffixDescription = data?.suffixDescription;

		if (this.data?.control && this.data?.comment) {
			this.nameControl = this.data.control;
			this.descriptionControl = this.data.comment;
		} else {
			this.nameControl = new UntypedFormControl(null, [Validators.required, UtilsService.firstCharacterAlphabet]);
			this.descriptionControl = new UntypedFormControl(null, [Validators.required, UtilsService.firstCharacterAlphabet]);
		}
	}

	ngOnInit(): void {}

	public primaryAction(): void {
		this.dialogRef.close({ name: this.nameControl.value, description: this.descriptionControl.value });
	}
}
