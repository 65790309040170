import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { SetUserPlan } from 'src/app/user-management/state/user-management.actions';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { getUserPlanState, UserManagementState, userPaymentPlan } from '../../../../../state/user-management.reducer';
import { IdAndNameInterface } from '../../../../../../shared/permisions/table-permissions/id-name.interface';

@Component({
	selector: 'app-subscription-confirmation',
	templateUrl: './subscription-confirmation.component.html',
	styleUrls: ['./subscription-confirmation.component.scss']
})
export class SubscriptionConfirmationComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: any[];

	public submitting: boolean;

	public currentRoute: string;
	public errorMessage: string;

	public duration: string;
	public tarrif: any;
	public paymentCard: any;

	constructor(
		private injector: Injector,
		private billingService: PaymentServiceApi,
		private router: Router,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		this.getDataFromStore();
		this.getTarrifDetails();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	get getPlanAmount(): string {
		let cur = this.tarrif?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.tarrif?.currency || 'USD'
		}).format(this.tarrif?.amount || 0);
		return cur + ' ' + amount;
	}

	public getTarrifDetails(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					details.amount = this.roundFigure(details.amount);
					var dates = details.nextInvoiceDate.split('T')[0].split('-');
					details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
				}
				this.planDetails = details;
			});
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	public getDataFromStore(): void {
		this.sharedStore.pipe(select(getUserPlanState), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.tarrif = detail.tarrif;
				this.duration = detail.duration;
				this.paymentCard = detail.paymentCard;
			}
		});
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public thankYouBackHome(): void {
		const payload: userPaymentPlan = { duration: '', tarrif: [], paymentCard: [] };
		this.store.dispatch(new SetUserPlan(payload));
		this.router.navigate(['/accounts']);
	}
}
