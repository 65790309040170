<div *ngIf="toastData" class="toast-root" [ngClass]="toastData.type">
	<div class="toast-progress-wrapper">
		<span class="progress" [ngStyle]="{ 'animation-duration': toastData.duration + 'ms' }"></span>
	</div>

	<div class="toast-wrapper">
		<div class="toast-content">
			<div class="toast-icon-wrapper">
				<img *ngIf="toastData.type == ToastV2TypeEnum.SUCCESS" class="toast-icon" src="assets/icons/toast-v2/success.svg" />
				<img *ngIf="toastData.type == ToastV2TypeEnum.ERROR" class="toast-icon" src="assets/icons/toast-v2/error.svg" />
				<img *ngIf="toastData.type == ToastV2TypeEnum.WARNING" class="toast-icon" src="assets/icons/toast-v2/error.svg" />
				<img *ngIf="toastData.type == ToastV2TypeEnum.INFO" class="toast-icon" src="assets/icons/toast-v2/info.svg" />
			</div>
			<div class="d-flex flex-column" style="gap: 2px;">
				<div class="toast-title" *ngIf="toastData.title">{{ toastData.title }}</div>
				<p class="toast-message">{{ toastData.message }}</p>
			</div>
		</div>

		<div class="toast-close-btn" (click)="onCloseButtonClick()">
			<mat-icon>close</mat-icon>
		</div>
	</div>
</div>
