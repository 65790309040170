export enum ToastV2TypeEnum {
	SUCCESS = 'Success',
	ERROR = 'Error',
	INFO = 'Info',
	WARNING = 'Warning'
}

export class ToastV2Model {
	public type: ToastV2TypeEnum;
	public title: string;
	public message: string;
	public duration: number;

	constructor(type: ToastV2TypeEnum, message: string, duration?: number, title?: string) {
		this.type = type;
		this.message = message;
		this.title = title ?? type;
		this.duration = duration ?? 5000;
	}
}

export interface ToastV2Model {
	type: ToastV2TypeEnum;
	message: string;
	duration: number;
}
