<div class="abs-shadow-wrapper">
	<ng-container [ngSwitch]="true">
		<ng-container *ngSwitchCase="inputType == CustomTextInputTypeEnum.PASSWORD" [ngTemplateOutlet]="input_password"></ng-container>
		<ng-container *ngSwitchCase="inputType == CustomTextInputTypeEnum.WEBSITE" [ngTemplateOutlet]="input_website"></ng-container>
		<ng-container *ngSwitchDefault [ngTemplateOutlet]="input_text"></ng-container>
	</ng-container>
</div>

<ng-template #input_text>
	<mat-form-field class="custom-shadow-field" [ngClass]="{ 'more-pad-right': showCloseIcon || showCloseIconOnlyOnHover }">
		<mat-label>
			{{ inputLabel }}
			<span class="mark-required" *ngIf="markRequired">*</span>
		</mat-label>
		<input matInput [formControl]="inputControl" (keydown.enter)="onEnterKeyPressEvent()" />
	</mat-form-field>

	<ng-container [ngTemplateOutlet]="suffix_close_icon"></ng-container>
</ng-template>

<ng-template #input_password>
	<mat-form-field class="custom-shadow-field more-pad-right">
		<mat-label>
			{{ inputLabel }}
			<span class="mark-required" *ngIf="markRequired">*</span>
		</mat-label>
		<input matInput [formControl]="inputControl" [type]="isPasswordVisible ? 'text' : 'password'" (keydown.enter)="onEnterKeyPressEvent()" />
	</mat-form-field>

	<img class="eye-icon" (click)="togglePasswordVisibility()" *ngIf="isPasswordVisible" src="assets/icons/password/eye.svg" />
	<img class="eye-icon" (click)="togglePasswordVisibility()" *ngIf="!isPasswordVisible" src="assets/icons/password/eye-slash.svg" />
</ng-template>

<ng-template #input_website>
	<mat-form-field class="custom-shadow-field more-pad-left" floatLabel="always" [ngClass]="{ 'more-pad-right': showCloseIcon || showCloseIconOnlyOnHover }">
		<mat-label>
			{{ inputLabel }}
			<span class="mark-required" *ngIf="markRequired">*</span>
		</mat-label>
		<input matInput [formControl]="inputControl" (keydown.enter)="onEnterKeyPressEvent()" />
	</mat-form-field>

	<div class="prefix-text">https://</div>
	<ng-container [ngTemplateOutlet]="suffix_close_icon"></ng-container>
</ng-template>

<ng-template #suffix_close_icon>
	<!-- * If this is true, show close icon always -->
	<mat-icon *ngIf="showCloseIcon" class="mat-close-btn-red close-icon" (click)="onCloseBtnClick()">close</mat-icon>

	<!-- * If this is true, show close icon only on hover -->
	<mat-icon *ngIf="!showCloseIcon && showCloseIconOnlyOnHover" class="mat-close-btn-red close-icon hoverable" (click)="onCloseBtnClick()">close</mat-icon>
</ng-template>
