import { Directive, ElementRef, Input } from '@angular/core';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';
import { RoleBasedControlActionEnum, UserRoleEnum } from '../models/user-role.model';

@Directive({
	selector: '[appRoleBasedControl]'
})
export class RoleBasedControlDirective {
	@Input() roleActionType: RoleBasedControlActionEnum = RoleBasedControlActionEnum.OTHERS;

	private actionsToDisableForStandard: RoleBasedControlActionEnum[] = [RoleBasedControlActionEnum.CANCEL_SUBSCRIPTION, RoleBasedControlActionEnum.PAYMENT];
	private actionsToDisableForAdmin: RoleBasedControlActionEnum[] = [RoleBasedControlActionEnum.CANCEL_SUBSCRIPTION];

	constructor(private el: ElementRef, private authV2Service: AuthenticationV2Service) {}

	ngOnInit() {
		this.initRoleControl();
	}

	private initRoleControl(): void {
		const userRole = this.authV2Service.getUserRole();

		if (userRole == UserRoleEnum.READ_ONLY) {
			this.disableElement();
			return;
		}
		if (userRole == UserRoleEnum.ADMIN) {
			if (this.actionsToDisableForAdmin.includes(this.roleActionType)) {
				this.disableElement();
			}
			return;
		}
		if (userRole == UserRoleEnum.STANDARD) {
			if (this.actionsToDisableForStandard.includes(this.roleActionType)) {
				this.disableElement();
			}
			return;
		}
		// ELSE HE IS OWNER
		// else { }
	}

	private disableElement(): void {
		this.el.nativeElement.disabled = true;
		this.el.nativeElement.classList.add('disabled');
	}
}
