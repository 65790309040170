import { Observable, Observer, of } from 'rxjs';
import { EnumerationEx } from '../../_models/enumeration';
import { UpdateProfile } from '../../user-management/models/update-profile';
import { AddUser } from '../../_models/user-models/register-details';
import { exhaust, map, take } from 'rxjs/operators';
import { SetPassword } from '../../_models/identity-models/set-password';
import { ChangePassword } from '../../_models/identity-models/change-password';
import { User } from '../../_models/user-models/user';
import { UpdateDetails } from '../../_models/user-models/update-details';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { UserRejectedReason } from './user-rejected-reason.enum';
import { NewUserModel } from '../../user-management/models/new-user-model';
import { UserStateEnum } from './user-state.enum';
import { SubUser, SubUserV2 } from 'src/app/user-management/models/sub-user.interface';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
	providedIn: 'root'
})
export class UserServiceApi {
	private readonly baseURL = `${BaseApiUrl.Users}users`;

	constructor(protected http: HttpClient, private sharedService: SharedService) {}

	public getCatalogs(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}/catalogs`);
	}

	public updateProfile(details: UpdateProfile): Observable<object> {
		return this.http.put(`${this.baseURL}/update-profile`, details);
	}

	public register(registerDetails: AddUser): Observable<object> {
		return this.http.post(`${this.baseURL}/`, registerDetails);
	}
	public campaignSignUp(userDetails: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/createInfluencerRecord`, userDetails);
	}

	public resetPassword(detail: string): Observable<object> {
		return this.http.put(`${this.baseURL}/send-password-link/`, { userEmail: detail });
	}

	public newResetPassword(email: string): Observable<any> {
		const formData = new FormData();
		formData.append('email', email);
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}users/forgot-password`, formData);
	}

	public setPassword(setPassword: SetPassword): Observable<object> {
		return this.http.put(`${this.baseURL}/set-first-password`, setPassword);
	}

	public forgotPassword(setPassword: SetPassword): Observable<object> {
		const formData = new FormData();
		formData.append('email', setPassword.EmailAddress);
		formData.append('new_password', setPassword.NewPassword);
		formData.append('token', setPassword.TwoFactorToken);

		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}users/update-password`, formData);
	}

	public changePassword(changePassword: ChangePassword): Observable<object> {
		return this.http.put(`${this.baseURL}/change-password`, changePassword).pipe(
			map(() => {
				return of({});
			})
		);
	}

	public authenticate(username: string, password: string): Observable<string> {
		const payload = {
			email: username,
			password: password
		};
		let queryParams = new HttpParams();
		if (this.sharedService.shopifyPayload) {
			Object.entries(this.sharedService.shopifyPayload).forEach(([key, value]) => {
				queryParams = queryParams.set(key, value.toString());
			});
			this.sharedService.shopifyPayload = null;
		}
		return this.http.post<string>(`${BaseApiUrl.SocialMessengerPython}users/sign-in`, payload, { params: queryParams });
	}

	public refreshUserToken(): Observable<string> {
		return this.http.get<string>(`${this.baseURL}/refresh-token`);
	}

	public googleSignin(idToken: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Users}ExternalSignIn/google-authenticate`, { idToken });
	}

	public googleSignup(offerId: number, idToken: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}ExternalSignUp/google-signup`, { offerId, idToken });
	}

	public verifyLollyEmail(emailAddress: string, token: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Users}Users/verify-lolly-email`, { emailAddress, token });
	}

	public getUserById(userId: number, isAdmin: boolean): Observable<User> {
		if (isAdmin) {
			return this.http.get<User>(`${BaseApiUrl.Users}BackOfficeUsers/employees/${userId}`);
		} else {
			return this.http.get<User>(`${this.baseURL}/${userId}`);
		}
	}

	public getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(`${this.baseURL}`);
	}

	public getUserActivity(): Observable<any[]> {
		return this.http.get<any[]>(`${this.baseURL}/account-activities`);
	}

	public getBusinessOwnerEmail(): Observable<string> {
		return this.http.get<string>(`${this.baseURL}/get-businessowner-email`);
	}

	public getAllUsersByOwnerFacebookId(ownerFacebookId: string): Observable<User[]> {
		return this.http.get<User[]>(`${this.baseURL}/owned/${ownerFacebookId}`);
	}

	public createClientEmployee(user: NewUserModel): Observable<number> {
		return this.http.post<number>(`${this.baseURL}/clientEmployees`, user);
	}

	public updateClientEmployee(userDetails: NewUserModel, userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/clientEmployees/${userId}`, userDetails);
	}

	public createAndActivateClientEmployee(user: NewUserModel): Observable<number | object> {
		return this.createClientEmployee(user).pipe(
			take(1),
			map(userId => this.activateUser(userId)),
			exhaust()
		);
	}

	public updateUser(userId: number, userDetails: UpdateDetails): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}`, userDetails);
	}

	public updateUserPicture(file: FormData): Observable<object> {
		return this.http.put(`${this.baseURL}/update-picture`, file);
	}

	public deactivateUser(userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}/disable-user`, { userId: userId });
	}

	public deactivateUserNew(user: any): Observable<object> {
		return this.http.put(`${this.baseURL}/disable-user`, user);
	}

	public activateUser(userId: number): Observable<object> {
		return this.http.put(`${this.baseURL}/${userId}/enable-user`, { userId: userId });
	}

	public backOfficeDeactivateUser(userId: number): Observable<object> {
		return this.http.put(`${BaseApiUrl.Users}BackOfficeUsers/${userId}/disable-user`, {});
	}

	public backOfficeActivateUser(userId: number): Observable<object> {
		return this.http.put(`${BaseApiUrl.Users}BackOfficeUsers/${userId}/enable-user`, { userId: userId });
	}

	public removeUser(userId: number): Observable<object> {
		return this.http.delete(`${this.baseURL}/${userId}`);
	}

	public backOfficeRemoveUser(userId: number): Observable<object> {
		return this.http.delete(`${BaseApiUrl.Users}BackOfficeUsers/${userId}`);
	}

	public getUserAccountStatus(filedId: number): Observable<UserStateEnum> {
		return of(1);
		// return this.http.get<UserStateEnum>(`${this.baseURL}/${filedId}/user-account-status`);
	}

	public confirmUserEmail(email: string): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Users}Users/verifyEmail?emailId=${email}`);
	}

	public bigCommerceAuth(payload: any): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${this.baseURL}/bigCommerce/signin`, payload, { observe: 'response' });
	}

	public checkIfSubUser(): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Users}Users/check-sub-user`);
	}

	public generateAdCopyData(request): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/generate`, request, { observe: 'response' });
	}

	public addLikedFeedback(request): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/feedback`, request, { observe: 'response' });
	}

	public getCreditScore(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/credit`, request, { observe: 'response' });
	}

	public getUserTemplates(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/most-used-templates`, request, { observe: 'response' });
	}

	public generateEmailData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/email`, request, { observe: 'response' });
	}

	public generateProductDescriptionData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/product-description`, request, { observe: 'response' });
	}

	public generateBlogData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/blog/generate`, request, { observe: 'response' });
	}

	public generateContentData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/content-improver`, request, { observe: 'response' });
	}

	public generateSocialData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/caption`, request, { observe: 'response' });
	}

	public generateHashTagData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/social/hashtags`, request, { observe: 'response' });
	}

	public generateFrameworkData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/framework`, request, { observe: 'response' });
	}

	public createUserProject(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/projects/create`, request, { observe: 'response' });
	}

	public getHistoryData(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/history`, request, { observe: 'response' });
	}

	public getAllSubUsers(): Observable<{ data: SubUserV2[] }> {
		return this.http.get<{ data: SubUserV2[] }>(`${BaseApiUrl.SocialMessengerPython}subusers`);
	}

	public addSubUser(payload: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}subusers`, payload);
	}

	public editSubUser(id: number, payload: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.SocialMessengerPython}subusers/${id}`, payload);
	}

	public deleteSubUser(id: number): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}subusers/${id}`);
	}

	public delSubUsers(sub_user_id: number, token: string): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialInfluencerPython}users/sub-users?sub_user_id=${sub_user_id}`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}

	public editSubUsers(role: string, sub_user_id: number, token: string): Observable<any> {
		return this.http.post<any>(
			`${BaseApiUrl.SocialInfluencerPython}users/sub-users?sub_user_id=${sub_user_id}&role=${role}`,
			{},
			{ headers: new HttpHeaders({ Authorization: token }) }
		);
	}
}
