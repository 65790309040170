import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { PayDialogComponent } from '../../../pay-dialog/pay-dialog.component';
import { MyPlanService } from '../../my-plan.service';

@Component({
	selector: 'app-all-plans',
	templateUrl: './all-plans.component.html',
	styleUrls: ['./all-plans.component.scss']
})
export class AllPlansComponent implements OnInit {
	// public selectedValue = 50000
	public selectedPlan: any[];
	public selectedPlanIndex: string;
	public btnDisable: boolean = true;
	public indexOfSlectedPlan: number;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public decodeJwtToken;

	constructor(private billingService: PaymentServiceApi, public _myPlan: MyPlanService, private matDialog: MatDialog) {}
	@Input() allTarrifPlans: any[];
	@Output() backToPlanPage = new EventEmitter<boolean>();
	@Input() pageTitle: string;
	@Input() dropDownData: Array<string>;
	@Output() FinalPlanBackToPlanPage = new EventEmitter<Array<Object>>();
	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.selectedPlan = this.allTarrifPlans.filter(val => val.currentOfferId == true);
		this.allTarrifPlans = [...this.selectedPlan, ...this.allTarrifPlans];
		let uniqueAllTarrifPlans = this.allTarrifPlans.filter((el, i) => {
			return i == this.allTarrifPlans.indexOf(el);
		});
		this.allTarrifPlans = uniqueAllTarrifPlans;
		// this.allTarrifPlans.sort((a, b) => (a.currentOfferId == true) ? 1 : (b.currentOfferId == false) ? -1 : 0)
	}

	onPlanChange(event) {
		// this.selectedPlan = []
		this.indexOfSlectedPlan = event.target.value;
		this.selectedPlan = [this.allTarrifPlans[event.target.value]];
		this.selectedPlan[0].currentOfferId ? (this.btnDisable = true) : (this.btnDisable = false);
	}

	public backToMyPlan(): void {
		this.backToPlanPage.emit(true);
		this._myPlan.componentToLoad = 'my-plan';
	}

	aplyChangeBtn() {
		let username = `${this.decodeJwtToken.user_firstname} ${this.decodeJwtToken.user_lastname}`;
		if (this._myPlan.componentToLoad == 'add-credit') {
			let topUpValue = this._myPlan.dropDownData.data[this._myPlan.dropDownData.selectedValue];
			let removeComma = topUpValue.replaceAll(',', '');
			let topupnumber = parseInt(removeComma);
			let rateToCharge = topupnumber * 0.02;
			let paymentDialog = this.matDialog.open(PayDialogComponent, {
				width: '692.5px',
				height: '582.68px',
				data: {
					amount: rateToCharge,
					InfluencerName: username
				}
			});
			paymentDialog.afterClosed().subscribe(res => {});
			// let payload = {
			//   "topUpType": this._myPlan.dropDownData.type,
			//   "topUpValue": topupnumber
			// }
			// this._myPlan.setCredits(payload).pipe(takeUntil(this.unsubscriber$))
			//   .subscribe((data) => {
			//   })
		} else if (this._myPlan.componentToLoad == 'all-plan') {
			let finalPlanSelected = [];

			//load pay screen
			this.matDialog.open(PayDialogComponent, {
				width: '692.5px',
				height: '582.68px',
				data: {
					amount: this.selectedPlan[0].amount,
					InfluencerName: username
				}
			});

			this.allTarrifPlans.map((el, i) => {
				// if (i == this.indexOfSlectedPlan) {
				//   el.currentOfferId = true
				// } else {
				//   el.currentOfferId = false
				// }
				// finalPlanSelected.push(el)
			});
			// this.FinalPlanBackToPlanPage.emit(finalPlanSelected)
			// this.backToMyPlan()
			// this._myPlan.componentToLoad = 'my-plan';
			// let contactIds = []
			// contactIds.push(this.decodeJwtToken.user_filed_id);
			// let payload = {
			//   newOfferId: this.selectedPlan[0].id,
			//   filedIds: contactIds
			// }
			// this.billingService.changUsereOffer(payload)
		}
	}

	AddCredits() {}
	disableApplyChangeButton() {
		if (this.btnDisable && this._myPlan.componentToLoad == 'all-plan') {
			return true;
		} else {
			return false;
		}
	}
}
