import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationV2Service } from 'src/app/authentication-v2/services/authentication-v2.service';

@Component({
	selector: 'app-google-account-landing',
	templateUrl: './google-account-landing.component.html',
	styleUrls: ['./google-account-landing.component.scss']
})
export class GoogleAccountLandingComponent implements OnInit {
	private token?: any;

	constructor(private router: Router, private authV2Service: AuthenticationV2Service) {
		this.token = router.getCurrentNavigation()?.extras?.state?.token;
	}

	ngOnInit(): void {
		if (!this.token) {
			this.router.navigate(['']);
		} else {
			this.authV2Service.signinUserWithToken(this.token);
		}
	}
}
