import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastV2Model, ToastV2TypeEnum } from '../../models/toast-v2.model';
import { takeUntil } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-toast-v2',
	templateUrl: './toast-v2.component.html',
	styleUrls: ['./toast-v2.component.scss']
})
export class ToastV2Component implements OnInit {
	public toastData?: ToastV2Model;
	public ToastV2TypeEnum = ToastV2TypeEnum;

	private timeoutId: any;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private toast: ToastNotificationService) {}

	ngOnInit(): void {
		this.initToastListeners();
	}

	private initToastListeners(): void {
		this.toast
			.getToasts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(toast => {
				this.showToast(toast);
			});
	}

	private showToast(toast: ToastV2Model): void {
		this.dismissExistingToast();

		this.toastData = toast;

		this.setNewToastTimeout();
	}

	private setNewToastTimeout(): void {
		this.timeoutId = setTimeout(() => {
			this.toastData = null;
			this.timeoutId = null;
		}, this.toastData.duration);
	}

	public onCloseButtonClick(): void {
		this.dismissExistingToast();
	}

	private dismissExistingToast(): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		this.toastData = null;
	}

	ngOnDestroy(): void {
		this.dismissExistingToast();
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
