import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { UserRoleClientGuard } from './shared/guards/user-role-client-guard.service';
import { NoResourceComponent } from './shared/404/no-resource.component';
import { TicketsViewComponent } from './sidenav/tickets-view/tickets-view.component';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { SidenavGroupComponent } from './sidenav/sidenav/sidenav-group.component';
import { StripeLandingComponent } from './stripe-landing/stripe-landing.component';
import { AppCustomPreloader } from './app-routing-preloader';
import { ShopifyLandingComponent } from './shopify-landing/shopify-landing.component';
import { NotificationsMainComponent } from './sidenav/components/notifications-main/notifications-main.component';
import { BigcommerceInstallationGuideComponent } from './bigcommerce-installation-guide/bigcommerce-installation-guide.component';
import { GoogleAccountLandingComponent } from './shared-components/google-account-landing/google-account-landing.component';
import { ShopifyBillingComponent } from './shared-components/shopify-billing/shopify-billing.component';
import { SmiV2Guard } from './shared/guards/smi-v2.guard';
import { GmailLandingComponent } from './shared-components/gmail-landing/gmail-landing.component';
import { BookDemoComponent } from './book-demo/book-demo.component';

const routes: Routes = [
	{
		path: 'authentication',
		loadChildren: () => import('./authentication-v2/authentication-v2.module').then(m => m.AuthenticationV2Module)
	},
	{
		path: '',
		component: SidenavGroupComponent,
		canActivate: [SmiV2Guard],
		children: [
			{ path: 'support-tickets', canActivate: [UserRoleClientGuard], component: TicketsViewComponent },
			{
				// canActivate: [UserRoleClientGuard],
				path: 'user-management',
				loadChildren: () => import('./user-management/new-sprint/user-sprint.module').then(m => m.UserSprintModule)
			},
			{
				path: 'back-office',
				canActivate: [UserRoleAdminGuard],
				loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule)
			},
			{
				// canActivate: [UserBusinessOwnerGuard],
				path: '',
				loadChildren: () => import('./social-media-influencer/social-media-influencer.module').then(m => m.SocialMediaInfluencerModule)
			},
			{
				path: 'notifications',
				component: NotificationsMainComponent
			}
		]
	},
	{ path: 'stripeLanding', component: StripeLandingComponent },
	{ path: 'shopifyBilling', component: ShopifyBillingComponent },
	{ path: 'shopifyLanding', component: ShopifyLandingComponent },
	{ path: 'bigcommerce/installation-guide', component: BigcommerceInstallationGuideComponent },
	{ path: '404', component: NoResourceComponent },
	{ path: 'google-landing', component: GoogleAccountLandingComponent },
	{ path: 'gmailAuth', component: GmailLandingComponent },
	{ path: 'book-demo', component: BookDemoComponent },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: AppCustomPreloader })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
