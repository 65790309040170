export enum UserRoleEnum {
	STANDARD = 'standard',
	ADMIN = 'admin',
	READ_ONLY = 'read_only',
	OWNER = 'owner'
}

export enum RoleBasedControlActionEnum {
	OTHERS = 'others',
	PAYMENT = 'payment',
	CANCEL_SUBSCRIPTION = 'cancel_subscription'
}
