import { initialSharedState, sharedReducer, SharedState } from '../shared/state/shared.reducer';
import { initialUserState, userReducer, UserState } from '../shared/state/user/user.reducer';
import { initialUserManagementState, userManagementReducer, UserManagementState } from '../user-management/state/user-management.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppAction, AppTypes } from './app.actions';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');

export interface AppState {
	userManagement: UserManagementState;
	shared: SharedState;
	user: UserState;
}

export const initialAppState: AppState = {
	userManagement: initialUserManagementState,
	shared: initialSharedState,
	user: initialUserState
};

export const reducers: ActionReducerMap<AppState> = {
	userManagement: userManagementReducer,
	shared: sharedReducer,
	user: userReducer
};

export enum AppStateSlices {
	UserManagement = 'UserManagement',
	Shared = 'Shared',
	Reporting = 'Reporting',
	Pixel = 'Pixel',
	Authentication = 'Authentication',
	User = 'User',
	Accounts = 'Accounts',
	AdsManager = 'AdsManager',
	CreativeBuilder = 'CreativeBuilder',
	SMI = 'SMI'
}

export function clearStateMetaReducer(reducer: any) {
	return function (state: AppState, action: AppAction) {
		if (action.type === AppTypes.ResetAppState) {
			state = initialAppState;
		}

		return reducer(state, action);
	};
}
