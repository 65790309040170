import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillingAndSubscriptionService } from 'src/app/shared/services/billing-and-subscriptions.service';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { SetUserPlan } from 'src/app/user-management/state/user-management.actions';
import { UserManagementState, userPaymentPlan } from 'src/app/user-management/state/user-management.reducer';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { MyPlanService } from './my-plan.service';

@Component({
	selector: 'app-my-plan',
	templateUrl: './my-plan.component_new.html',
	styleUrls: ['./my-plan.component_new.scss']
})
export class MyPlanComponent implements OnInit {
	public tarrifPlans: any[];
	public activeTarrifPlan: any[];
	public selectedTarrifPlan: any = [];
	public durationControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
	public featuresMyPlan: Array<string>;
	public loading: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public planDetails: any;
	public decodeJwtToken: any;
	public subPageTitle: string;

	public duration: string;
	public tarrif: any;
	public paymentCard: any;

	public allPlansComp: boolean = false;
	public componentToLoad: string;
	public dropBoxData: Array<string>;

	public planData;
	public features = ['fwfwufuwh', 'fuwhfiuhw'];

	constructor(
		private router: Router,
		private sharedStore: Store<SharedState>,
		private billingService: PaymentServiceApi,
		private store: Store<UserManagementState>,
		private tarrifsService: BillingAndSubscriptionService,
		public _myPlan: MyPlanService
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.componentToLoad = this._myPlan.componentToLoad;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.loadTarrifPlan();
		this.getActiveSubscriptions();
		// this.getAllOffers();
		this.onSelectDuration('month');
		// this.getTarrifDetails();
	}
	public loadTarrifPlan(): void {
		this.tarrifPlans = [];
		this.activeTarrifPlan = [];
	}

	public getActiveSubscriptions(): void {
		const token = localStorage.getItem(StorageKey.token);
		this._myPlan.getActiveSubs(token).subscribe(res => {
			this.loading = false;
			this.planData = res;
		});
	}

	public getAllOffers(): void {
		this.tarrifsService
			.getBaseOffers()
			.pipe(take(1))
			.subscribe((res: any) => {
				if (res) {
					res.filter((tarrif: any, i: number) => {
						this.tarrifPlans.push({
							id: tarrif?.id,
							tag: tarrif?.name,
							price: +tarrif?.fixedPrice,
							amount: tarrif?.fixedPrice,
							tagLine: 'Per month',
							currency: tarrif?.currency,
							header: i == 1 ? 'Recommended' : '',
							features: ['All features', 'Advanced Service', 'Up to 10 million impressions', '150 SignNow contracts'],
							allowdedSubUser: tarrif.allowdedSubUser,
							influencerSearchCredit: tarrif.influencerSearchCredit,
							currentOfferId: tarrif.currentOfferId
							// disable: i == 0 ? true : false,
							// buttonText: i == 0 ? 'Current Plan' : 'Upgrade Plan'
						});

						if (tarrif.currentOfferId == true) {
							this.activeTarrifPlan.push({
								id: tarrif?.id,
								tag: tarrif?.name,
								price: +tarrif?.fixedPrice,
								amount: tarrif?.fixedPrice,
								tagLine: 'Per month',
								currency: tarrif?.currency,
								header: i == 1 ? 'Recommended' : '',
								features: ['All features', 'Advanced Service', 'Up to 10 million impressions', '150 SignNow contracts'],
								disable: i == 0 ? true : false,
								buttonText: i == 0 ? 'Current Plan' : 'Upgrade Plan',
								allowdedSubUser: tarrif.allowdedSubUser,
								influencerSearchCredit: tarrif.influencerSearchCredit
							});
						}
					});
				}
			});
	}

	public changePlan(data) {
		this.tarrifPlans = data;
		data.map((tarrif, i) => {
			if (tarrif.currentOfferId) {
				this.activeTarrifPlan = [];
				this.activeTarrifPlan.push(tarrif);
			}
		});
	}

	public changeCompSetting(value) {
		this.allPlansComp = !this.allPlansComp;
		this._myPlan.componentToLoad = 'my-plan';
	}
	public showAddCreditPage() {
		this.dropBoxData = ['30,000', '35,000', '40,000', '45,000', '50,000'];
		this._myPlan.componentToLoad = 'add-credit';
		this._myPlan.dropDownData.data = this.dropBoxData;
		this._myPlan.dropDownData.type = 'InfluencerSearchCredit';
		this.subPageTitle = 'Add Credit';
	}

	public editSubUser() {
		this.dropBoxData = ['3', '5', '7', '8', '10'];
		this._myPlan.dropDownData.data = this.dropBoxData;
		this._myPlan.dropDownData.type = 'SubUser';
		this._myPlan.componentToLoad = 'add-credit';
		this.subPageTitle = 'Edit sub user';
	}
	public getTarrifDetails(): void {
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						details.amount = this.roundFigure(details.amount);
						var dates = details.nextInvoiceDate.split('T')[0].split('-');
						details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
					}
					this.planDetails = details;
				},
				error => {
					console.log(error);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	private normalizePlane(tarrifId: number): void {
		let index = this.tarrifPlans.findIndex(tarrif => tarrif.id == tarrifId);
		if (index > -1) {
			this.tarrifPlans[index].disable = true;
			this.tarrifPlans[index].buttonText = 'Current Plan';
		}
	}

	public onSelectDuration(duration: string): void {
		for (let ln in this.tarrifPlans) {
			let index = ln;
			if (this.tarrifPlans[ln].price !== 'Free Trial') {
				this.tarrifPlans[ln].amount = duration == 'month' ? this.tarrifPlans[ln].price * 1 : this.tarrifPlans[ln].price * 12;
				this.tarrifPlans[ln].tagLine = duration == 'month' ? 'Per month' : 'Per year';
				this.tarrifPlans[ln].buttonText = +index === 0 && duration == 'month' ? 'Current Plan' : 'Upgrade Plan';
				this.tarrifPlans[ln].disable = +index === 0 && duration == 'month' ? true : false;
			}
		}
		this.durationControl.setValue(duration);
		this.updateStoreData();
	}
	public onSelectTarrifPlan(event: any): void {
		this.selectedTarrifPlan = event;
		this.updateStoreData();
		this.router.navigate(['/user-management/my-plan/payment']);
	}
	public updateStoreData(): void {
		const payload: userPaymentPlan = { duration: this.durationControl.value, tarrif: this.selectedTarrifPlan, paymentCard: [] };
		this.store.dispatch(new SetUserPlan(payload));
	}
}
